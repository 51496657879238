import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header>
      <div className="logo">
        <h1>
          NikhilVisionAI Quantum Solutions
          <img 
            src="/logo.png" 
            alt="NikhilVisionAI Quantum Solutions Logo" 
            style={{ width: '30px', height: 'auto', marginLeft: '10px', verticalAlign: 'middle' }}
          />
        </h1>
      </div>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/research">Research</Link></li>
          <li><Link to="/publications">Publications</Link></li>
          <li><Link to="/team">Team</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
