import React from 'react';

function Team() {
  return (
    <div className="team">
      <h1>Our Team</h1>
      <p>We have a diverse team of researchers, scientists, and engineers working together to push the boundaries of AI and computer vision.</p>
      <section>
        <h2>Key Members</h2>
        <ul>
          <li>Dr. Nikhil Sharma - Founder & Lead Researcher</li>
          <li>Dr. Priya Patel - Senior AI Scientist</li>
          <li>Dr. Alex Johnson - Computer Vision Expert</li>
          <li>Dr. Maria Rodriguez - Quantum Computing Specialist</li>
        </ul>
      </section>
    </div>
  );
}

export default Team;

