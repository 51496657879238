import React from 'react';

function Publications() {
  return (
    <div className="publications">
      <h1>Publications</h1>
      <p>Our team regularly publishes research papers in top-tier conferences and journals.</p>
      <section>
        <h2>Recent Publications</h2>
        <ul>
          <li>Smith, J. et al. (2023). "Quantum-Inspired Neural Networks for Computer Vision Tasks." CVPR 2023.</li>
          <li>Patel, A. et al. (2022). "Deep Learning Approaches in Medical Image Segmentation." Nature Machine Intelligence.</li>
          <li>Kumar, R. et al. (2022). "Advancements in AI-Driven Autonomous Systems." ICRA 2022.</li>
        </ul>
      </section>
    </div>
  );
}

export default Publications;

