import React from 'react';

function Contact() {
  return (
    <div className="contact">
      <h1>Contact Us</h1>
      <p>Get in touch with us for collaborations, inquiries, or to learn more about our research.</p>
      <section>
        <h2>Contact Information</h2>
        <p>NikhilVisionAI Quantum Solutions</p>
        <p>Address: No 15, 2nd block, Anjaneya temple road, Peenya 1st stage, Bengaluru - 566058, Karnataka, India</p>
        <p>Phone: +91-7022445257</p>
        <p>Email: admin@nikhilvisionaiquantumsolutions.com</p>
      </section>
    </div>
  );
}

export default Contact;
