import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="home">
      <section className="hero">
        <h1>Welcome to NikhilVisionAI Quantum Solutions</h1>
        <p>Pioneering research in computer vision, machine learning, and quantum computing</p>
      </section>
      
      <section className="principal-investigator">
        <h2>Meet Our Principal Investigator</h2>
        <div className="pi-info">
          <img 
            src="https://motherlandhospital.com/wp-content/uploads/2021/07/dr-nikhil-sharma.webp" 
            alt="Dr. Nikhil Sharma" 
            className="pi-photo" 
          />
          <div>
            <h3>Dr. Nikhil Sharma</h3>
            <p>Dr. Nikhil Sharma is a renowned expert in computer vision and quantum computing. With over 15 years of experience in the field, he has led groundbreaking research projects and published numerous papers in top-tier conferences and journals.</p>
            <p>Dr. Sharma's work focuses on integrating quantum computing principles with computer vision and deep learning techniques to solve complex real-world problems.</p>
          </div>
        </div>
      </section>

      <section className="featured-research">
        <h2>Our Focus Areas</h2>
        <div className="research-grid">
          <div className="research-item">
            <h3>Computer Vision</h3>
            <p>Advanced object detection and image segmentation</p>
          </div>
          <div className="research-item">
            <h3>Machine Learning</h3>
            <p>Developing novel algorithms for complex data analysis</p>
          </div>
          <div className="research-item">
            <h3>Quantum Computing</h3>
            <p>Exploring quantum algorithms for AI applications</p>
          </div>
          <div className="research-item">
            <h3>Deep Learning</h3>
            <p>Pushing the boundaries of neural network architectures</p>
          </div>
        </div>
      </section>

      <section className="featured-projects">
        <h2>Featured Projects</h2>
        <div className="project-list">
          <div className="project-item">
            <h3>AI-Powered Assistant for the Visually Impaired</h3>
            <p>Using computer vision and natural language processing to help blind people navigate and interact with their environment.</p>
          </div>
          <div className="project-item">
            <h3>Quantum-Enhanced Image Recognition</h3>
            <p>Leveraging quantum computing to improve the accuracy and speed of image recognition tasks.</p>
          </div>
          <div className="project-item">
            <h3>Vision Transformer for Medical Imaging</h3>
            <p>Applying transformer architectures to enhance medical image analysis and diagnosis.</p>
          </div>
        </div>
        <Link to="/research" className="btn-more-projects">View More Projects</Link>
      </section>
    </div>
  );
}

export default Home;
