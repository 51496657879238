import React from 'react';

function Footer() {
  return (
    <footer>
      <p>&copy; 2023 NikhilVisionAI Quantum Solutions. All rights reserved.</p>
      <p>Phone: +91-7022445257</p>
      <p>Email: admin@nikhilvisionaiquantumsolutions.com</p>
    </footer>
  );
}

export default Footer;
