import React from 'react';

function Research() {
  return (
    <div className="research">
      <h1>Our Research</h1>
      <p>At NikhilVisionAI Quantum Solutions, we conduct cutting-edge research in various fields of AI, computer vision, and quantum computing, with a focus on applications that can make a positive impact on society.</p>
      
      <section className="current-projects">
        <h2>Current Projects</h2>
        <div className="project-grid">
          <div className="project-item">
            <h3>AI-Powered Assistant for the Visually Impaired</h3>
            <p>This project aims to develop a wearable device that uses advanced computer vision techniques and natural language processing to assist blind individuals in their daily lives. Key features include:</p>
            <ul>
              <li>Real-time object detection and scene description</li>
              <li>Text recognition and reading aloud</li>
              <li>Facial recognition for identifying known individuals</li>
              <li>Navigation assistance using AR audio cues</li>
            </ul>
          </div>
          
          <div className="project-item">
            <h3>Quantum-Enhanced Image Recognition</h3>
            <p>We're exploring how quantum computing can be leveraged to improve image recognition tasks. This project involves:</p>
            <ul>
              <li>Developing quantum algorithms for feature extraction</li>
              <li>Creating quantum-classical hybrid models for image classification</li>
              <li>Optimizing quantum circuits for faster processing</li>
            </ul>
          </div>
          
          <div className="project-item">
            <h3>Vision Transformer for Medical Imaging</h3>
            <p>This project focuses on applying transformer architectures to enhance medical image analysis. We're working on:</p>
            <ul>
              <li>Adapting vision transformers for various medical imaging modalities (X-ray, MRI, CT)</li>
              <li>Developing attention mechanisms for highlighting potential abnormalities</li>
              <li>Creating explainable AI models for assisting medical professionals</li>
            </ul>
          </div>
          
          <div className="project-item">
            <h3>Deep Learning for Autonomous Navigation</h3>
            <p>We're developing advanced deep learning models to improve autonomous navigation systems. This includes:</p>
            <ul>
              <li>Real-time semantic segmentation for understanding complex environments</li>
              <li>3D object detection and tracking using LiDAR and camera fusion</li>
              <li>Reinforcement learning for decision-making in dynamic scenarios</li>
            </ul>
          </div>
        </div>
      </section>
      
      <section className="collaboration">
        <h2>Collaboration Opportunities</h2>
        <p>We are always open to collaborations with other research institutions, industry partners, and individual researchers. If you're interested in working with us or learning more about our projects, please visit our <a href="/contact">Contact page</a>.</p>
      </section>
    </div>
  );
}

export default Research;
